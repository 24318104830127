
import { Avatar, Timeline } from 'components/ui'
import { useDispatch, useSelector } from 'react-redux'
import { apiDeleteWarrantyNote } from 'services/LoveWarrantyServices';
import { popNotification } from 'views/warranties/CreateWarranty';
import { getWarrantyNotes, setEditingNote } from './store/dataSlice';
import { useState } from 'react';
import { ConfirmDialog } from 'components/shared';

export function formatPostDate(date) {
    const postDate = new Date(date);
    // show date as Just now, 1 minute ago, 1 hour ago, 1 day ago, 1 week ago, 1 month ago, 1 year ago
    const currentDate = new Date();
    const diff = currentDate - postDate;
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);

    if (months > 0) {
        return months === 1 ? '1 month ago' : `${months} months ago`;
    }

    if (weeks > 0) {
        return weeks === 1 ? '1 week ago' : `${weeks} weeks ago`;
    }

    if (days > 0) {
        return days === 1 ? '1 day ago' : `${days} days ago`;
    }

    if (hours > 0) {
        return hours === 1 ? '1 hour ago' : `${hours} hours ago`;
    }

    if (minutes > 0) {
        return minutes === 1 ? '1 minute ago' : `${minutes} minutes ago`;
    }

    return 'Just now';
}

const TimelineAvatar = ({ children, ...rest }) => {
    return (
        <Avatar {...rest} size={25} shape="circle">
            {children}
        </Avatar>
    )
}
const WarrantyNotesList = ({
    warranty_id
}) => {
    const dispatch = useDispatch()
    const { loading, warrantyNotes } = useSelector((state) => state.warrantyNotes.data)
    const user = useSelector((state) => state.auth.user);

    const [confirmDialogOptions, setConfirmDialogOptions] = useState({
        isOpen: false,
        confirmText: 'Confirm',
        message: 'Are you sure you want to proceed?',
        onConfirm: () => { },
        onCancel: () => { },
    })

    const onEdit = (id) => {
        dispatch(
            setEditingNote({
                id,
                body: warrantyNotes.find(note => note.id === id).body
            })
        )
    }

    const onDelete = async (id) => {
        setConfirmDialogOptions({
            ...confirmDialogOptions,
            isOpen: false
        })

        try {
            await apiDeleteWarrantyNote(warranty_id, id)
            popNotification('Note deleted successfully', 3000, 'Success', 'success')
            dispatch(getWarrantyNotes(warranty_id))
        } catch (error) {
            popNotification('An error occurred while deleting note')
        }
    }

    const renderNotes = (notes) => {
        return notes.map((note) => (
            <Timeline.Item
                id={note.id}
                media={
                    <TimelineAvatar className="bg-amber-500">
                        {note.created_by.first_name[0]}{note.created_by.last_name[0]}
                    </TimelineAvatar>
                }
            >
                <p className="warranty-note my-1 flex items-end gap-2" data-note-id={note.id}>
                    <span className="font-semibold text-gray-900 dark:text-gray-100">
                        {note.created_by.first_name} {note.created_by.last_name}
                    </span>
                    <span className="text-xs">{formatPostDate(note.created_at)}</span>
                    <div className="flex items-center">
                        {note.created_by.id === user.id && (
                            <>
                                <i className="far text-sm fa-edit mr-2 cursor-pointer" onClick={() => {
                                    onEdit(note.id)
                                }}></i>
                                <i className="far text-sm fa-trash-alt cursor-pointer" onClick={() => {
                                    setConfirmDialogOptions({
                                        isOpen: true,
                                        confirmText: 'Delete',
                                        message: 'Are you sure you want to delete this note?',
                                        onConfirm: () => onDelete(note.id),
                                        onCancel: () => setConfirmDialogOptions({
                                            ...confirmDialogOptions,
                                            isOpen: false
                                        })
                                    })
                                }}></i>
                            </>
                        )}
                    </div>
                </p>
                {/* animate bg */}
                <div className="warranty-note-body flex flex-col w-full mt-4 dark:bg-custom-100 p-4 rounded-lg transition-all duration-300"
                    data-note-id={note.id} dangerouslySetInnerHTML={{ __html: note.body }} />
            </Timeline.Item>
        ))
    }

    return (
        <div className="mt-5">
            <ConfirmDialog
                isOpen={confirmDialogOptions.isOpen}
                onClose={confirmDialogOptions.onCancel}
                // onRequestClose={}
                type={'warning'}
                title={'Warning'}
                onCancel={confirmDialogOptions.onCancel}
                onConfirm={confirmDialogOptions.onConfirm}
                confirmButtonColor={'custom-300'}
                confirmText={confirmDialogOptions.confirmText}
                cancelText='Cancel'
            >
                <p className="">
                    {confirmDialogOptions.message}
                </p>
            </ConfirmDialog>

            {/* Loader */}
            {loading && (
                <div className="text-center text-gray-500 dark:text-gray-400 pb-3 text-lg">
                    <i className="fas fa-spinner fa-spin text-lg mr-2"></i>
                    Loading notes...
                </div>
            )}

            {warrantyNotes.length === 0 && !loading && (
                <div className="text-center text-gray-500 dark:text-gray-400 pb-3 text-xl">
                    <img src="/img/others/no-notification.png" alt="No notes" className="w-1/4 mx-auto" />
                    No notes found
                </div>
            )}

            {warrantyNotes.length > 0 && (
                <Timeline>
                    {renderNotes(warrantyNotes)}
                </Timeline>
            )}
        </div>
    )
}

export default WarrantyNotesList