import { RichTextEditor } from 'components/shared'
import { Button } from 'components/ui'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { apiCreateWarrantyNote, apiUpdateWarrantyNote } from 'services/LoveWarrantyServices'
import { popNotification } from 'views/warranties/CreateWarranty'
import { getWarrantyNotes, setEditingNote } from './store/dataSlice'

const WarrantyNoteCreateForm = ({
    warranty_id
}) => {
    const dispatch = useDispatch()

    const { loading, warrantyNotes, editingNote } = useSelector((state) => state.warrantyNotes.data)
    const [notes, setNotes] = useState('')

    useEffect(() => {
        if (editingNote) {
            setNotes(editingNote.body)
            window.scrollTo(0, 0)
        }
    }, [editingNote])

    const handleChange = (val) => {
        setNotes(val)
    }

    const onCreate = async () => {
        try {
            if (!notes) return

            if (editingNote) {
                // Call API to update note
                await apiUpdateWarrantyNote(warranty_id, editingNote.id, {
                    body: notes
                })

                dispatch(getWarrantyNotes(warranty_id))
                dispatch(setEditingNote(null))
                setNotes('')
                popNotification('Note updated successfully', 3000, 'Success', 'success')

                document.querySelector(`.warranty-note-body[data-note-id="${editingNote.id}"]`)?.classList.add('border')
                document.querySelector(`.warranty-note-body[data-note-id="${editingNote.id}"]`)?.classList.add('border-green-300')
                document.querySelector(`.warranty-note[data-note-id="${editingNote.id}"]`)?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center'
                })

                setTimeout(() => {
                    document.querySelector(`.warranty-note-body[data-note-id="${editingNote.id}"]`)?.classList.remove('border')
                    document.querySelector(`.warranty-note-body[data-note-id="${editingNote.id}"]`)?.classList.remove('border-green-300')
                }, 3000)
                return
            }

            // Call API to create note
            await apiCreateWarrantyNote(warranty_id, {
                body: notes
            })

            // Clear notes
            setNotes('')
            dispatch(getWarrantyNotes(warranty_id))
            popNotification('Note created successfully', 3000, 'Success', 'success')
        } catch (error) {
            popNotification('An error occurred while creating note')
        }
    }

    return (
        <div className="flex flex-col w-full mt-8 px-6 dark:bg-custom-900 p-4 rounded-lg">
            <div className="flex justify-between w-full items-center mb-6">
                <h2 className="text-xl font-semibold">
                    <i className="far text-lg fa-sticky-note mr-2"></i>
                    Notes
                </h2>
                <span className="text-xs dark:text-gray-400">
                    Total: {loading ? 'Loading...' : warrantyNotes.length}
                </span>
            </div>

            <RichTextEditor
                value={notes}
                onChange={handleChange}
            />

            <Button
                className="mt-4 w-fit"
                size="sm"
                variant="solid"
                onClick={onCreate}
                disabled={loading || !notes}
            >
                {editingNote ? 'Update' : 'Create'} Note
            </Button>
        </div>
    )
}

export default WarrantyNoteCreateForm